// src/config/firebase.js

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCvt5BvHDEQKKnaR53dimDahw2iQ4xYhHg",
  authDomain: "tevecompras-fcm.firebaseapp.com",
  databaseURL: "https://tevecompras-fcm.firebaseio.com",
  projectId: "tevecompras-fcm",
  storageBucket: "tevecompras-fcm.firebasestorage.app",
  messagingSenderId: "808247182873",
  appId: "1:808247182873:web:ecd4eae8688fe1a5140c88",
  measurementId: "G-TMEKMY4RY3"
};


export const FIREBASE_VAPID_KEY = "BGsOSmJ8phB6DdjxZwj9FjA3jHSeSXSsYpzHwAg30Qg4U0IzLeFWsJgvNB0OywKLs0ixC3etIpUzYUlEN9XSoqI";

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestForToken = () => {
  console.log('pasa x request token')
  return getToken(messaging, { vapidKey: FIREBASE_VAPID_KEY })
    .then((currentToken) => {
      if (currentToken) {
        console.log('devolvio')
        return currentToken;
      } else {
        alert(
          "No registration token available. Request permission to generate one."
        );
        return null;
      }
    })
    .catch((err) => {
      alert("An error occurred while retrieving token - " + err);
      return null;
    });
};

onMessage(messaging, ({ notification }) => {
  new Notification(notification.title, {
    body: notification.body,
    icon: notification.icon,
  });
});