const dev = {
    site_url:"http://rest.tevecompras.local:4001",
    apiUrl: "https://omnidev.tevecompras.com"
};

const prod = {
    site_url:"http://rest.tevecompras.local:4001",
    apiUrl: "https://omnidev.tevecompras.com"
};

const config = process.env.NODE_ENV === 'production'
  ? prod
  : dev;

export default config;