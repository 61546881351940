import React, { useEffect, useState } from "react";
import Api from '../../state/Api';
import Axios from "axios";
import CircularProgress from '@material-ui/core/CircularProgress';

// This function is asynchronous since it returns a promise
// It converts a binary blob into a base64 string representation
function blobToBase64(blob) {

    return new Promise((resolve) => {
        const reader = new FileReader();
        
        reader.readAsDataURL(blob);

        reader.onloadend = function () {
            
            resolve(reader.result);
        };
    });
}

function fetchImageAsBase64(url, campaignId) {

    if(campaignId == 14){
        var instance = Axios.create();
        delete instance.defaults.headers.common['Authorization'];

        return instance.get(url, { responseType: 'blob' })
            .then((blob) => blobToBase64(blob.data))
            .then((base64) => base64);
    }else{
        return Api.req.get(url, { responseType: 'blob' })
            .then((blob) => blobToBase64(blob.data))
            .then((base64) => base64);
    }

}

const SecureImage = (props) => {
    const [image, setImage] = useState(false);


    useEffect( ()  => {

        let replaceUrl = props.src.replace("https://omni.tevecompras.com", "https://omnidev.tevecompras.com");
        async function getData () {
                const base64String = await fetchImageAsBase64(replaceUrl, props.campaignid);
                console.log('genera secure image')
                setImage(base64String)
        }


        getData()

    }, [])

    if (image) {

        return (<img src={image.toString()} alt="cargando" width={200} />)
    }
    return (<div><CircularProgress /> ...</div> )

}

export default SecureImage;