import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import MainApp from './components/App';

// Redux
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxThunk from 'redux-thunk';
import { createRoot } from 'react-dom/client';

import rootReducer from './state/reducer';

import * as serviceWorker from './serviceWorker';
//import Firebase from './firebase';

const store = createStore(rootReducer, composeWithDevTools(
  applyMiddleware(reduxThunk) 
));

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <Provider store={ store }>
    <MainApp />
  </Provider>
);

serviceWorker.register();
//Firebase.register();